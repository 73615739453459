// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {config} from 'rxjs';
import * as firebase from 'firebase';

export const environment = {
  production: false,
  origin        : 'https://one-click-interview.web.app',
  firebaseConfig: {
    apiKey: 'AIzaSyCDv-Ldxtfp2r3t2IVkzEMC7OA39oWxQQM',
    authDomain: 'one-click-interview.firebaseapp.com',
    databaseURL: 'https://one-click-interview.firebaseio.com',
    projectId: 'one-click-interview',
    storageBucket: 'one-click-interview.appspot.com',
    messagingSenderId: '66324887020',
    appId: '1:66324887020:web:57aeca0c48e3eefeb3bcc9',
    measurementId: 'G-YVKPP74DKX'
  }
};
firebase.initializeApp(environment.firebaseConfig)


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

