// see the codelab at github.com/googlecodelabs/webrtc-web for guidance.
// see https://www.youtube.com/watch?v=pIHYtUJ7hkY&t=683s for GREAT!!! overview.

import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})

export class AppComponent implements OnInit, AfterViewChecked {
  startCameraBtnDisabled: boolean;
  createRoomBtnDisabled: boolean;
  joinRoomBtnDisabled: boolean;
  hangUpBtnDisabled: boolean;
  confirmBtnDisabled: boolean;

  title = 'One Click Interviews';


  listVideoDevices: SelectItem[];
  listAudioDevices: SelectItem[];
  selectedVideo: string;
  selectedAudio: string;
  private selectedVideoDeviceID: string;
  private selectedAudioDeviceID: string;
  localVideo: any;
  remoteVideo: any;


  private mediaStreamConstraints: {
    video:
      { deviceId: { exact: string } },
    audio: {
      deviceId: { exact: string }
    }
  };

  cameraAndMicSelected: boolean;
  actionsTaken: boolean;
  cameraSelected: boolean;
  microphoneSelected: boolean;
  private localMediaStream: MediaStream;
  private remoteMediaStream: MediaStream;


  // the below lists urls stun servers are NOT for production.  Must replace with commercial stun servers.
  // configuration = {
  //   iceServers: [
  //     {
  //       urls: [
  //         'stun:stun1.l.google.com:19302',
  //         'stun:stun2.l.google.com:19302',
  //         'stun:stun1.l.google.com:19302',
  //         'stun:stun2.l.google.com:19302',
  //         'stun:stun3.l.google.com:19302',
  //         'stun:stun4.l.google.com:19302',
  //         'stun:stun.stunprotocol.org:3478',
  //       ],
  //     },
  //   ],
  //   iceCandidatePoolSize: 10,
  // };
  configuration = {
    iceServers: [

      {
        urls: 'turn:numb.viagenie.ca:3478',
               'username': 'laudolux@gmail.com',
               'credential': '72*numb',
      },
      {
        urls: 'turn:192.158.29.39:3478?transport=udp',
        credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
        username: '28224511:1379330808'
      },
      {
        urls: 'turn:192.158.29.39:3478?transport=tcp',
        credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
        username: '28224511:1379330808'
      },
      {
        urls: 'turn:turn.bistri.com:80',
        credential: 'homeo',
        username: 'homeo'
      },
      {
        urls: 'turn:turn.anyfirewall.com:443?transport=tcp',
        credential: 'webrtc',
        username: 'webrtc'
      }
    ],
    iceCandidatePoolSize: 100,
  };

  // keeping this as we have turn server account with viagenie and may need it
  // note the world can see this password, so don't use long term passwords. see https://tools.ietf.org/html/draft-uberti-behave-turn-rest-00
  // so rotate passwords every few minutes, otherwise everyone can use your expensive turn server.
  // configuration = {
  //   'iceServers': [
  //     {'urls': 'stun:stun.services.mozilla.com'},
  //     {'urls': 'stun:stun.l.google.com:19302'},
  //     {
  //       'urls': 'turn:numb.viagenie.ca',
  //       'username': 'laudolux@mail.com',
  //       'credential': '72*numb',
  //       'credentialType': 'password',
  //     }
  //   ],
  // };

  peerConnection = null; // the actual RTCPeerConnection object hat will be established between two users.

  roomId = '';

  private roomsCollection: AngularFirestoreCollection;

  callerOrCallee: any;
  showCommunicationMessage: boolean;
  urlToCallee: any;
  roomIsClosed: boolean;


  constructor(
    private angularFirestore: AngularFirestore,
    private messageService: MessageService,
  ) {
    this.roomsCollection = angularFirestore.collection('rooms');
    this.startCameraBtnDisabled = true;
    this.createRoomBtnDisabled = true;
    this.joinRoomBtnDisabled = true;
    this.hangUpBtnDisabled = true;
    this.confirmBtnDisabled = false;
    this.showCommunicationMessage = false;
    this.roomIsClosed = false;

  }


  ngOnInit(): void {
    const initialConstraints = {video: true, audio: true};
    navigator.mediaDevices.getUserMedia(initialConstraints)
      .then(() => this.populateListOfDevices());

    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.populateListOfDevices();
    });
  }

  ngAfterViewChecked(): void {
    this.localVideo = document.querySelector('video#localVideo');
    this.remoteVideo = document.querySelector('video#remoteVideo');
  }

// ***** start local media functions
  async populateListOfDevices() {
    await this.getConnectedDevices('videoinput', cameras => this.listVideoDevices = cameras);
    await this.getConnectedDevices('audioinput', microphones => this.listAudioDevices = microphones);

  }

  getConnectedDevices(type, callback) {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const filtered = devices.filter(device => device.kind === type);
        callback(filtered);
      });
  }

  onLocalVideoSelection(event) {
    this.selectedVideo = event.label;
    this.selectedVideoDeviceID = event.deviceId;
    this.cameraSelected = true;

    if (this.cameraSelected && this.microphoneSelected) {
      this.cameraAndMicSelected = true;
      this.startCameraBtnDisabled = false;
    }
  }

  onLocalAudioSelection(event) {
    this.selectedAudio = event.value.label;
    this.selectedAudioDeviceID = event.value.deviceId;
    this.microphoneSelected = true;
    if (this.cameraSelected && this.microphoneSelected) {
      this.cameraAndMicSelected = true;
      this.startCameraBtnDisabled = false;
    }
  }

  isVideoSelected() {
    if (this.selectedVideoDeviceID == null) {
      this.cameraSelected = false;
      this.selectedVideo = 'Please select a camera before starting video.';
    } else {
      this.cameraSelected = true;
    }
  }

  isAudioSelected() {
    if (this.selectedAudioDeviceID == null) {
      this.microphoneSelected = false;
      this.selectedAudio = 'Please select a camera before starting video.';
    } else {
      this.microphoneSelected = true;
    }
  }

  async startCamera() {
    await this.isVideoSelected();
    await this.isAudioSelected();
    if (this.cameraSelected == true && this.microphoneSelected == true) {
      this.mediaStreamConstraints = {
        video: {deviceId: this.selectedVideoDeviceID ? {exact: this.selectedVideoDeviceID} : undefined},
        audio: {deviceId: this.selectedAudioDeviceID ? {exact: this.selectedAudioDeviceID} : undefined},
      };

      navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints)
        .then((mediaStream) => {
          this.localMediaStream = mediaStream;
          this.localVideo.srcObject = this.localMediaStream;
        })
        .catch((error) => {
          console.log(error);
        });
      this.remoteMediaStream = new MediaStream();
      this.remoteVideo.srcObject = this.remoteMediaStream;

      this.startCameraBtnDisabled = true;
      this.joinRoomBtnDisabled = false;
      this.createRoomBtnDisabled = false;
      this.hangUpBtnDisabled = false;
      this.actionsTaken = true;
    }
  }

  async stopMediaStreams() {

    this.localVideo.srcObject = null;
    this.remoteVideo.srcObject = null;
    this.startCameraBtnDisabled = true;
    this.joinRoomBtnDisabled = true;
    this.createRoomBtnDisabled = true;
    this.hangUpBtnDisabled = true;
    this.showCommunicationMessage = false;
    this.roomId = '';
    this.actionsTaken = false;
    this.roomIsClosed = true;


    if (this.localMediaStream.active) {
      this.localMediaStream.getVideoTracks().forEach(track => track.stop());
      this.selectedAudio = null;
      this.selectedVideo = null;
    }
    if (this.remoteMediaStream) {
      this.remoteMediaStream.getTracks().forEach(track => track.stop());
    }

    if (this.peerConnection) {
      this.peerConnection.close();
    }
    this.mediaStreamConstraints = undefined;

    if (this.roomId) {
      const roomRef = this.angularFirestore.collection('rooms').doc(this.roomId);

      await roomRef.collection(`calleeCandidates`).snapshotChanges().pipe(
        map(candidates => candidates.map(a => {
          const data = a.payload.doc.data();
          const candidateId = a.payload.doc.id;
          const candidatePath = `rooms/${this.roomId}/calleeCandidates/${candidateId}`;
          this.angularFirestore.doc(candidatePath).delete()
            .catch(reason => {
              console.log('delete failed: ', candidatePath, reason);
            });
        }))
      );
      await roomRef.delete();
    }
  }

  async hangupAction() {
    await this.stopMediaStreams();
    this.selectedVideo = null;
    this.cameraAndMicSelected = false;
    document.getElementById('selectedRoom')['value'] = '';

    this.trace('Ending call.');
  }

  sendInviteEmail() {
    // const emailBody = `Please click here http://localhost:4200/?roomId=${this.roomId}</a> to join your interview.`;
    const emailBody = `Please join <a>https://one-click-interview.firebaseapp.com//?roomId=${this.roomId} target="_blank"</a>  your interview.`;
    console.log('Function: sendInviteEmail,\nthis.roomId', this.roomId);
    this.showCommunicationMessage = false;
    this.actionsTaken = false;
    return window.location.href = 'mailto:?subject=Let\'s Do a Remote Interview!&cc=kentmercier@gmail.com, ' + '&body=' + `${emailBody}`;

  }

  async createRoom() {
    this.createRoomBtnDisabled = true;
    this.joinRoomBtnDisabled = true;
    this.confirmBtnDisabled = true;
    this.hangUpBtnDisabled = false;


    let eventCollection = [];

    this.peerConnection = new RTCPeerConnection(this.configuration);
    await this.registerPeerConnectionListeners();
    await this.localMediaStream.getTracks().forEach(track => {
      this.peerConnection.addTrack(track, this.localMediaStream);
    });

    await this.peerConnection.addEventListener('icecandidate', event => {
      if (!event.candidate) {
        return;
      }
      eventCollection.push(event.candidate.toJSON());
    });

    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(offer);

    const roomWithOffer = {
      'offer': {
        type: offer.type,
        sdp: offer.sdp,
      },
    };
    await this.roomsCollection.add(roomWithOffer)
      .then(doc => {
        this.roomId = doc.id;
      });

    this.urlToCallee = `https://one-click-interview.firebaseapp.com/?roomId=${this.roomId}`;
    const roomRef = await this.angularFirestore.collection('rooms').doc(`${this.roomId}`);

    const callerCandidatesCollection = roomRef.collection(`callerCandidates`);
    eventCollection.forEach(eventCandidate => {
      callerCandidatesCollection.add(eventCandidate);
    });


    await this.peerConnection.addEventListener('track', event => {
      event.streams[0].getTracks().forEach(async track => {
        await this.remoteMediaStream.addTrack(track);
      });
    });


    roomRef.valueChanges().subscribe(async data => {
      const dataAnswer = data['answer'];
      if (!this.peerConnection.currentRemoteDescription && data && dataAnswer) {
        const rtcSessionDescription = new RTCSessionDescription(dataAnswer);
        await this.peerConnection.setRemoteDescription(rtcSessionDescription);
      }
    });

    this.showCommunicationMessage = true;
    this.callerOrCallee = `You have created a room. Give this room number, ${this.roomId}, to the person you are calling.`;

    const calleeCandidateCollection = roomRef.collection('calleeCandidates');

    const callees = calleeCandidateCollection.stateChanges(['added']).pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      }))
    );

    await callees.forEach(callee => {
      console.log('callee[0]', callee[0]);
      const calleeModified = {candidate: callee[0]['candidate'], sdpMLineIndex: callee[0]['sdpMLineIndex'], sdpMid: callee[0]['sdpMid']};
      this.peerConnection.addIceCandidate((new RTCIceCandidate(calleeModified)));
    });


  }

  async joinRoom() {
    // enteredRoom = enteredRoom.trim();
    this.createRoomBtnDisabled = true;
    this.confirmBtnDisabled = true;
    this.joinRoomBtnDisabled = true;
    this.showCommunicationMessage = false;
    this.actionsTaken = false;


    // await this.joinRoomById(enteredRoom);
    await this.joinRoomById();
  }

  async getUrlVars() {
    const queryString = window.location.search;
    // console.log('Function: getUrlVars,\nqueryString', queryString);
    const urlParams = new URLSearchParams(queryString);
    // console.log('Function: getUrlVars,\nurlParams', urlParams);
    const x = urlParams.get('roomId');
    // console.log('Function: getUrlVars,\nx', x);
    return x;
  }

  async joinRoomById() {
    this.roomId = await this.getUrlVars();
console.log('Function: joinRoomById,\nthis.roomId', this.roomId);
    this.joinRoomBtnDisabled = true;
    this.showCommunicationMessage = false;
    this.callerOrCallee = `You are joining a call in room ${this.roomId}, the caller is already present.`;
    // const angularFirestore = firebase.firestore();
    const roomRef = this.angularFirestore.collection('rooms').doc(`${this.roomId}`);
    const calleeCandidatesCollection = roomRef.collection(`calleeCandidates`);

    const roomSnapshot = await roomRef.get();
    roomSnapshot.subscribe(async roomSnapshot => {
      if (roomSnapshot) {
        this.peerConnection = new RTCPeerConnection(this.configuration);
        await this.registerPeerConnectionListeners();
        await this.localMediaStream.getTracks().forEach(track => {
          this.peerConnection.addTrack(track, this.localMediaStream);
        });

        // Code for collecting ICE candidates below
        await this.peerConnection.addEventListener('icecandidate', event => {
          if (!event.candidate) {
            return;
          }
          calleeCandidatesCollection.add(event.candidate.toJSON());
        });
        // Code for collecting ICE candidates above

        await this.peerConnection.addEventListener('track', event => {
          event.streams[0].getTracks().forEach(track => {
            this.remoteMediaStream.addTrack(track);
          });
        });

        // Code for creating SDP answer below
        let offer;
        try {
          offer = roomSnapshot.data().offer;
        } catch (e) {
          console.log('Function: ,\ne', e);
          this.roomIsClosed = true;
          await this.hangupAction();
          return;
        }
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);

        const roomWithAnswer = {
          answer: {
            type: answer.type,
            sdp: answer.sdp,
          },
        };
        await roomRef.update(roomWithAnswer);
        // Code for creating SDP answer above

        // Listening for remote ICE candidates below
        await roomRef.collection('callerCandidates').valueChanges(snapshot => {
          snapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              let data = change.doc.data();
              await this.peerConnection.addIceCandidate(new RTCIceCandidate(data));
            }
          });
        });
        // Listening for remote ICE candidates above
      } else {
        this.roomIsClosed = true;

      }
    });
  }


  registerPeerConnectionListeners() {
    this.peerConnection.addEventListener('icegatheringstatechange', () => {
      console.log(
        `ICE gathering state changed: ${this.peerConnection.iceGatheringState}`);
    });

    this.peerConnection.addEventListener('connectionstatechange', () => {
      console.log(`Connection state change: ${this.peerConnection.connectionState}`);
    });

    this.peerConnection.addEventListener('signalingstatechange', () => {
      console.log(`Signaling state change: ${this.peerConnection.signalingState}`);
    });

    this.peerConnection.addEventListener('iceconnectionstatechange ', () => {
      console.log(
      `ICE connection state change: ${this.peerConnection.iceConnectionState}`);
    });
  }

// ***** Stop signaling with firestore functions


// Logs an action (text) and the time when it happened on the console.
  trace(text) {
    text = text.trim();
    const now = (window.performance.now() / 1000).toFixed(3);
    console.log(now, text);
  }
}




