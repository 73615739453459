import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {ButtonModule} from 'primeng/button';
import {BrowserModule} from '@angular/platform-browser';
import {ListboxModule} from 'primeng/listbox';
import {FormsModule} from '@angular/forms';
import {FUNCTIONS_ORIGIN} from '@angular/fire/functions';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { InterviewComponent } from './interview/interview.component';
import {FieldsetModule} from 'primeng/fieldset';


@NgModule({
  declarations: [AppComponent, InterviewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    ButtonModule,
    ListboxModule,
    FormsModule,
    ToastModule,
    InputTextModule,
    FieldsetModule,

  ],
  providers: [
    {provide: FUNCTIONS_ORIGIN, useValue: environment.origin},
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
